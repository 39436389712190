<template>
  <router-view />
</template>

<script>
import "@/assets/js/survey/common";
export default {
  name: "SurveyPromotionLayout",
};
</script>

<style scoped>
@import "~@/assets/fonts/gmarketSans.css";
@import "~@/assets/css/survey/normalize.css";
@import "~@/assets/css/survey/common.css";
@import "~@/assets/css/survey/content.css";
</style>
